function getPDFcondensed(index,print){
	console.log('condense')
 		
 	 $('.container').hide();

	 window.scrollTo(0, 0)

	 var HTML_Width = $(".canvas_div_pdf").width();
	 var HTML_Height = $(".canvas_div_pdf").height();

	 var top_left_margin = 0;
	 var PDF_Width = HTML_Width+(top_left_margin*2);
	 var PDF_Height = HTML_Height+(top_left_margin*2);
	 var canvas_image_width = HTML_Width;
	 var canvas_image_height = HTML_Height;
	 
	 var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
	 
	 var pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
	 
	 html2canvas($("#proposal-page-0")[0],{allowTaint:true, scale:4}).then(function(canvas)
	 {
		 canvas.getContext('2d');
		 var imgData = canvas.toDataURL("image/jpeg", 1.0);
		 pdf.setPage(1);
		 pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);

		 html2canvas($("#proposal-page-cond")[0],{allowTaint:true, scale:4}).then(function(canvas)
		 {
			canvas.getContext('2d');
			var imgData = canvas.toDataURL("image/jpeg", 1.0);
			pdf.addPage();
			pdf.setPage(2);
			pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);

			pdf.save("SAS_Proposal_" + $('input[name="name"]').val() + ".pdf");
			$('.container').show();
			location.reload();
		});
		 
	});
	
	
 };
 
function resetForm(){
	window.location = window.location.href.split("?")[0];
}

	
function copylink(){
	var dummy = document.createElement('input'),
    text = window.location.href;

document.body.appendChild(dummy);
dummy.value = text;
dummy.select();
document.execCommand('copy');
document.body.removeChild(dummy);	
alert('A link to the current form has been saved to you clipboard')
}