$(document).ready(function(){
	
//  variables
 	var xml
 	var toilets = {}
 	var showerheads = {}
 	var faucets = {}
 	var addons = {}
 	var waterUsage = {}
 	var heatUsage = {}
 	var energyUsage = {}
 	var moneySavings = {}
 	var totals = {}
 	
 	var selections = {}
 	
 	var productList = ['toilet','shower','faucet-kitchen','faucet-bathroom']
 	var toiletAddons = ['anglestop','flangerepair','baseplate',/*'toiletrebate'*/]
 	var generalAddons = ['toiletseat', 'supplyline']
 	
//  init
 	$("#tempDiv").load("xml/reference.xml", function (response, status, xhr) {
		if(status == "success")
		{
			var xmlDoc = $.parseXML($("#tempDiv").html())
			xml = $(xmlDoc)
			
			initProducts()
			
			if(document.location.search.length) {
				
				reloadSelections()
				
			} else {
				
				resetToDefaults()
			}
		
			initListeners()
			updateForm()
		}
    });
    
    function initListeners()
    {
	    $('select').change(updateForm)
	    $('input').change(updateForm)
	    
	    $('select[name=toilet-select]').change(function(){ setInput('toilet-cost',selections['toilet'].cost); selections['toilet'].altCost = "none" })
	    $('select[name=shower-select]').change(function(){ setInput('shower-cost',selections['shower'].cost); selections['shower'].altCost = "none"})
	    $('select[name=faucet-kitchen-select]').change(function(){ setInput('faucet-kitchen-cost',selections['faucet-kitchen'].cost); selections['faucet-kitchen'].altCost = "none" })
	    $('select[name=faucet-bathroom-select]').change(function(){ setInput('faucet-bathroom-cost',selections['faucet-bathroom'].cost); selections['faucet-bathroom'].altCost = "none" })
	    
	    $('input[name=toilet-cost]').change(function(){ selections['toilet'].altCost = getInput('toilet-cost') })
	    $('input[name=shower-cost]').change(function(){ selections['shower'].altCost = getInput('shower-cost') })
	    $('input[name=faucet-kitchen-cost]').change(function(){ selections['faucet-kitchen'].altCost = getInput('faucet-kitchen-cost') })
	    $('input[name=faucet-bathroom-cost]').change(function(){ selections['faucet-bathroom'].altCost = getInput('faucet-bathroom-cost') })
	    
		$('input[name="num-units"]').change(updateNumUnits)
	    $('select[name=heat-units]').change(updateHeatCost)
	    $('select[name=water-units]').change(updateWaterCost)

    }
    
    function updateForm()
    {
	    updateSelections()
	    
	    calculate()
	    
	    serializeSelections()
	    
	    displayResults()
	    
	    updatePDF()
    }
    
     function updateSelections()
    {
		
		$('input').each(function(){
			
			if($(this).attr('type') == 'checkbox')
			{
				$(this).prop('checked') ? selections[$(this).attr('name')] = 'checked' : selections[$(this).attr('name')] = 'unchecked';
			}
			else
			{
				selections[$(this).attr('name')] = $(this).val()
			}
		})
		
		$('select').each(function(){
		
			selections[$(this).attr('name')] = $(this).val()
		})
		
		selections['toilet'] = toilets[getIndex(getSelect('toilet-select'))]
		selections['shower'] = showerheads[getIndex(getSelect('shower-select'))]
		selections['faucet-kitchen'] = faucets[getIndex(getSelect('faucet-kitchen-select'))]
		selections['faucet-bathroom'] = faucets[getIndex(getSelect('faucet-bathroom-select'))]
		
		selections['toiletseat'] = addons[getIndex($("input[name='toiletseat']:checked").val() )]
		selections['supplyline'] = addons[getIndex($("input[name='supplyline']:checked").val() )]
		// selections['warranty'] = addons[getIndex($("input[name='warranty']:checked").val() )]
		
		selections['toiletseat-val'] = $('input[name=toiletseat]:checked').val()
		selections['supplyline-val'] = $('input[name=supplyline]:checked').val()
		// selections['warranty-val'] = $('input[name=warranty]:checked').val()
		var dateTemp = selections['delivery-date'].substring(5,999) + '-' + selections['delivery-date'].substring(0,4)
		selections['delivery-date-flip'] = dateTemp
	}
	
	function serializeSelections()
    {
	    var recursiveEncoded = $.param( selections );
		var recursiveDecoded = decodeURIComponent( $.param( selections ) );
		
		if (history.pushState) {
	        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + recursiveDecoded;
	        window.history.pushState({path:newurl},'',newurl);
	        
	    }  
    }
    
    function calculate()
    {
	   	totals = { totalMoneySavings : 0, totalGallonsSaved : 0, totalMoneySavingsWater : 0, totalCurrentHeatUsage : 0, totalFutureHeatUsage : 0, totalHeatSavings : 0 , totalGasSavings : 0, totalElectricSavings : 0, totalMoneySavingsGas : 0, totalMoneySavingsElectric : 0, }
	    
	    for(var i=0;i<productList.length; i++)
	    {
		    var p = productList[i]
		    
		    waterUsage[p] = calcWaterUsage(p)
		    totals['totalGallonsSaved'] += waterUsage[p].gallonsSaved
		    
		    heatUsage[p] = calcHeatUsage(p)
		    totals['totalCurrentHeatUsage'] += heatUsage[p].currentHeatUsage
		    totals['totalFutureHeatUsage'] += heatUsage[p].futureHeatUsage
		    totals['totalHeatSavings'] += heatUsage[p].heatSavings
		    
		    energyUsage[p] = calcEnergyUsage(p)
		    totals['totalGasSavings'] += energyUsage[p].gasSavings
		    totals['totalElectricSavings'] += energyUsage[p].electricSavings
		    
		    moneySavings[p] = calcMoneySavings(p)
		    totals['totalMoneySavingsGas'] += moneySavings[p].moneySavingsGas
		    totals['totalMoneySavingsElectric'] += moneySavings[p].moneySavingsElectric
		    
		    totals['totalMoneySavingsWater'] += waterUsage[p].moneySaved
	    }
	    
	    if(getSelect('heat-units') == 'Gas' )
	    {
		    totals['totalMoneySavings'] = totals['totalMoneySavingsWater'] + totals['totalMoneySavingsGas']
	    }
	    else
	    {
		    totals['totalMoneySavings'] = totals['totalMoneySavingsWater'] + totals['totalMoneySavingsElectric']
	    }
	    
    }
    
    function displayResults()
    {
	    totals['subtotal'] = 0.00
	    totals['totalCost'] = 0.00
	    totals['roi'] = 0
	    // var rebate = 0
	    
	    setNumber('gallons-saved',totals['totalGallonsSaved'])

	    setNumber('pdf-1-total-water-saved',totals['totalGallonsSaved'])
	    setNumber('pdf-2-total-water-saved',totals['totalGallonsSaved'])
	    setNumber('pdf-3-total-water-saved',totals['totalGallonsSaved'])
	    setNumber('pdf-4-total-water-saved',totals['totalGallonsSaved'])
	    setNumber('pdf-cond-total-water-saved',totals['totalGallonsSaved'])
	    
	    if(getSelect('heat-units') == 'Gas' )
	    {
		    setText('energy-saved', numberWithCommas(totals['totalGasSavings'].toFixed(2)) + " therms" )
		    setText('pdf-1-total-energy-saved', numberWithCommas(totals['totalGasSavings'].toFixed(2)) + " therms" )
		    setText('pdf-2-total-energy-saved', numberWithCommas(totals['totalGasSavings'].toFixed(2)) + " therms" )
		    setText('pdf-3-total-energy-saved', numberWithCommas(totals['totalGasSavings'].toFixed(2)) + " therms" )
		    setText('pdf-4-total-energy-saved', numberWithCommas(totals['totalGasSavings'].toFixed(2)) + " therms" )
	    }
	    else
	    {
		    setText('energy-saved', numberWithCommas(totals['totalElectricSavings'].toFixed(2)) + " kWh" )
		    setText('pdf-1-total-energy-saved', numberWithCommas(totals['totalElectricSavings'].toFixed(2)) + " kWh" )
		    setText('pdf-2-total-energy-saved', numberWithCommas(totals['totalElectricSavings'].toFixed(2)) + " kWh" )
		    setText('pdf-3-total-energy-saved', numberWithCommas(totals['totalElectricSavings'].toFixed(2)) + " kWh" )
		    setText('pdf-4-total-energy-saved', numberWithCommas(totals['totalElectricSavings'].toFixed(2)) + " kWh" )
	    }
	    
	    setNumber('money-saved',totals['totalMoneySavings'])
	    
	    setText('pdf-1-total-money-saved', '$ ' + numberWithCommas(totals['totalMoneySavings'].toFixed(2)))
	    setText('pdf-2-total-money-saved', '$ ' + numberWithCommas(totals['totalMoneySavings'].toFixed(2)))
	    setText('pdf-3-total-money-saved', '$ ' + numberWithCommas(totals['totalMoneySavings'].toFixed(2)))
	    setText('pdf-4-total-money-saved', '$ ' + numberWithCommas(totals['totalMoneySavings'].toFixed(2)))
	    setText('pdf-cond-total-money-saved', '$ ' + numberWithCommas(totals['totalMoneySavings'].toFixed(2)))
	    
	    for(var i = 0; i<productList.length; i++)
	    {
		    var p = productList[i]
		    
		    if(!$('#no-' + p).prop('checked') )
		    {
		    
		    	setText('summary-'+ p +'-select', selections[p].name)
				setText('summary-'+ p +'-quantity', selections['num-' + p])
				setNumber('summary-'+ p +'-cost', selections[p + '-cost'])
				setNumber('summary-'+ p +'-total', ( selections[p + '-cost'] * selections['num-' + p] ) )
				
				setText('pdf-4-'+ p +'-total', '$ ' + numberWithCommas(( selections[p + '-cost'] * selections['num-' + p] ).toFixed(2)) )
				
				
				totals['subtotal'] += (getInput(p + '-cost') * getInput('num-' + p))
			}
			else
			{
				setText('summary-'+ p +'-select', 'none' )
				setText('summary-'+ p +'-quantity', 0 )
				setNumber('summary-'+ p +'-cost', 0.00)
				setNumber('summary-'+ p +'-total', 0.00 )
			}
	    }
	    
	    for(var i = 0; i<toiletAddons.length; i++)
	    {
		    var p = toiletAddons[i]
		    
		    if(selections['addon-' + p] /*&& p != 'toiletrebate'*/ && selections['addon-' + p] == 'checked')
		    {
		    
		    	setText('summary-'+ p +'-quantity', selections['quantity-' + p])
		    	setNumber('summary-'+ p +'-cost', selections['amount-' + p])
		    	setNumber('summary-'+ p +'-total', selections['quantity-' + p] * selections['amount-' + p])

		    	setText('pdf-4-'+ p +'-quantity', selections['quantity-' + p])
		    	setText('pdf-4-'+ p +'-cost', '$ ' + numberWithCommas(selections['amount-' + p]))
		    	setText('pdf-4-'+ p +'-total', '$ ' + numberWithCommas(( selections['amount-' + p] * selections['quantity-' + p] ).toFixed(2)) )
		    	
		    	totals['subtotal'] += (selections['quantity-' + p] * selections['amount-' + p])
			}
			else
			{
				setText('summary-'+ p +'-quantity', '0')
		    	setNumber('summary-'+ p +'-cost', 0)
		    	setNumber('summary-'+ p +'-total', 0)
		    	
		    	setText('pdf-4-'+ p +'-quantity', '-')
		    	setText('pdf-4-'+ p +'-cost', '-')
		    	setText('pdf-4-'+ p +'-total', '-')
			}
	    }
	    
	    for(var i = 0; i<generalAddons.length; i++)
	    {
		    var p = generalAddons[i]
		    
		    setText('summary-'+ p +'-quantity', selections['num-toilet'])
		    setNumber('summary-' + p + '-cost', selections[p].cost)
		    setNumber('summary-' + p + '-total', selections['num-toilet'] * selections[p].cost)
		    
		    setText('pdf-4-'+ p +'-quantity', selections['num-toilet'])
		    setText('pdf-4-' + p + '-cost','$ ' + selections[p].cost)
		    setText('pdf-4-'+ p +'-total', '$ ' + numberWithCommas(( selections[p].cost * selections['num-toilet'] ).toFixed(2)) )
		     
		    totals['subtotal'] += (selections['num-toilet'] * selections[p].cost)
	    }

	    //temp
	    // setText('summary-warranty-quantity', selections['num-toilet'])
	    // setNumber('summary-warranty-cost', '0.00')
	    // setNumber('summary-warranty-total', '0.00')
	    // setText('pdf-4-warranty-quantity', selections['num-toilet'])
	    
	    setNumber('summary-other-total', selections['other-cost'] * selections['other-quantity'])

	    setText('pdf-4-other-cost-total','$ ' + numberWithCommas((selections['other-cost'] * selections['other-quantity']).toFixed(2)))

	    totals['subtotal'] += ( selections['other-cost'] * selections['other-quantity'])

	    setNumber('summary-subtotal', totals['subtotal'])
	    setText('pdf-4-subtotal','$ '+ numberWithCommas((totals['subtotal']).toFixed(2)))
	    
	    // if(selections['addon-toiletrebate'])
	    // {
		   //  rebate = selections['amount-toiletrebate'] * selections['num-toilet']
	    // }
	    
	    totals['totalCost'] = totals['subtotal'] /*- parseFloat(rebate)*/
	    
	    // setNumber('summary-rebate',rebate)
	    // setText('pdf-4-rebate','$ '+ numberWithCommas((rebate).toFixed(2)))
	    setNumber('summary-total-cost', totals['totalCost'])
	    setNumber('total-cost', totals['totalCost'])
	    setText('pdf-4-total-cost','$ '+ numberWithCommas((totals['totalCost']).toFixed(2)))
	    setText('pdf-cond-total-cost','$ '+ numberWithCommas((totals['totalCost']).toFixed(2)))
	    
	    totals['roi'] = totals['totalCost'] / totals['totalMoneySavings'] * 12
	    totals['propertyValue'] = totals['totalMoneySavings'] / selections['cap-rate']
	    
		setText('roi',parseFloat(totals['roi']).toFixed(1))
		setText('pdf-1-roi',parseFloat(totals['roi']).toFixed(1))
		setText('pdf-2-roi',parseFloat(totals['roi']).toFixed(1))
		setText('pdf-3-roi',parseFloat(totals['roi']).toFixed(1))
		setText('pdf-4-roi',parseFloat(totals['roi']).toFixed(1))
		setText('pdf-cond-roi',parseFloat(totals['roi']).toFixed(1)+' MOS')
		
		setNumber('propety-value', totals['propertyValue'])
		setText('pdf-4-property-value','$ '+ numberWithCommas((totals['propertyValue']).toFixed(2)))
		setText('pdf-cond-property-value','$ '+ numberWithCommas((totals['propertyValue']).toFixed(2)))
    }
    
    
    function updatePDF()
    {	
		$('.pdf-checkbox').hide();
		
		// $('#pdf-1-toilet-select-check' + selections['toilet'].index).show()
		$('#pdf-1-toilet-image').attr('src','images/product-images/toilets/' + selections['toilet'].productImage)
		$('#pdf-4-toilet-image').attr('src','images/product-images/toilets/' + selections['toilet'].productImage)
		$('#pdf-cond-toilet-image').attr('src','images/product-images/toilets/' + selections['toilet'].productImage)
		setText('pdf-4-toilet-name',selections['toilet'].name)
		setText('pdf-cond-toilet-name',selections['toilet'].name)

		setText('pdf-2-shower-select-name',selections['shower'].name)
		$('#pdf-2-shower-image').attr('src','images/product-images/showerheads/' + selections['shower'].productImage)
		$('#pdf-4-shower-image').attr('src','images/product-images/showerheads/' + selections['shower'].productImage)
		$('#pdf-cond-shower-image').attr('src','images/product-images/showerheads/' + selections['shower'].productImage)
		setText('pdf-4-shower-name',selections['shower'].name)
		setText('pdf-cond-shower-name',selections['shower'].name)

		$('#pdf-2-faucet-kitchen-image').attr('src','images/product-images/aerators/kitchen/' + selections['faucet-kitchen'].productImage)
		$('#pdf-4-faucet-kitchen-image').attr('src','images/product-images/aerators/kitchen/' + selections['faucet-kitchen'].productImage)
		$('#pdf-cond-faucet-kitchen-image').attr('src','images/product-images/aerators/kitchen/' + selections['faucet-kitchen'].productImage)
		setText('pdf-4-faucet-kitchen-name',selections['faucet-kitchen'].name)
		setText('pdf-cond-faucet-kitchen-name',selections['faucet-kitchen'].name)

		setText('pdf-2-faucet-bathroom-select-name',selections['faucet-bathroom'].name)
		$('#pdf-2-faucet-bathroom-image').attr('src','images/product-images/aerators/bathroom/' + selections['faucet-bathroom'].productImage)
		$('#pdf-4-faucet-bathroom-image').attr('src','images/product-images/aerators/bathroom/' + selections['faucet-bathroom'].productImage)
		$('#pdf-cond-faucet-bathroom-image').attr('src','images/product-images/aerators/bathroom/' + selections['faucet-bathroom'].productImage)
		setText('pdf-4-faucet-bathroom-name',selections['faucet-bathroom'].name)
		setText('pdf-cond-faucet-bathroom-name',selections['faucet-bathroom'].name)

		$('#pdf-3-addon-check' + selections['toiletseat'].index).show();
		$('#pdf-3-addon-check' + selections['supplyline'].index).show();
		// $('#pdf-3-addon-check' + selections['warranty'].index).show();

		if(selections['no-toilet'] == 'checked')
		{
			$('#pdf-1-toilet-image').hide();
			$('#pdf-1-num-toilet').hide();
			$('#pdf-1-current-toilet-flowrate').hide();

			$('#pdf-4-toilet-image').hide();
			$('#pdf-4-toilet-name').hide();
			$('#pdf-cond-toilet-image').hide();
			$('#pdf-cond-toilet-name').hide();
			$('#pdf-cond-toilet-brand').hide();
			$('#pdf-4-num-toilet').hide();
			$('#pdf-4-toilet-cost').hide();
			$('#pdf-4-toilet-total').hide();
		}
		else
		{
			$('#pdf-1-toilet-image').show();
			$('#pdf-1-num-toilet').show();
			$('#pdf-1-current-toilet-flowrate').show();

			$('#pdf-4-toilet-image').show();
			$('#pdf-4-toilet-name').show();
			$('#pdf-cond-toilet-image').show();
			$('#pdf-cond-toilet-name').show();
			$('#pdf-cond-toilet-brand').show();
			$('#pdf-4-num-toilet').show();
			$('#pdf-4-toilet-cost').show();
			$('#pdf-4-toilet-total').show();

			$('#pdf-1-toilet-select-check' + selections['toilet'].index).show()
			
		}

		if(selections['no-shower'] == 'checked')
		{
			$('#pdf-2-shower-image').hide();
			$('#pdf-2-num-shower').hide();
			$('#pdf-2-current-shower-flowrate').hide();

			$('#pdf-4-shower-image').hide();
			$('#pdf-4-shower-name').hide();
			$('#pdf-cond-shower-image').hide();
			$('#pdf-cond-shower-name').hide();
			$('#pdf-cond-shower-brand').hide();
			$('#pdf-4-num-shower').hide();
			$('#pdf-4-shower-cost').hide();
			$('#pdf-4-shower-total').hide();
		}
		else
		{
			$('#pdf-2-shower-image').show();
			$('#pdf-2-num-shower').show();
			$('#pdf-2-current-shower-flowrate').show();

			$('#pdf-4-shower-image').show();
			$('#pdf-4-shower-name').show();
			$('#pdf-cond-shower-image').show();
			$('#pdf-cond-shower-name').show();
			$('#pdf-cond-shower-brand').show();
			$('#pdf-4-num-shower').show();
			$('#pdf-4-shower-cost').show();
			$('#pdf-4-shower-total').show();

			if(selections['shower'].index < 5)
			{
				$('#pdf-2-shower-select-check' + selections['shower'].index).show()
				$('#pdf-2-shower-select-name').hide()	
			}
			else
			{
				$('#pdf-2-shower-select-other').show()
				$('#pdf-2-shower-select-name').show()
			}
		}

		if(selections['no-faucet-kitchen'] == 'checked')
		{
			$('#pdf-2-faucet-kitchen-image').hide();
			$('#pdf-2-num-faucet-kitchen').hide();
			$('#pdf-2-current-faucet-kitchen-flowrate').hide();

			$('#pdf-4-faucet-kitchen-image').hide();
			$('#pdf-4-faucet-kitchen-name').hide();
			$('#pdf-cond-faucet-kitchen-image').hide();
			$('#pdf-cond-faucet-kitchen-name').hide();
			$('#pdf-cond-faucet-kitchen-brand').hide();
			$('#pdf-4-num-faucet-kitchen').hide();
			$('#pdf-4-faucet-kitchen-cost').hide();
			$('#pdf-4-faucet-kitchen-total').hide();
		}
		else
		{
			$('#pdf-4-faucet-kitchen-image').show();
			$('#pdf-4-faucet-kitchen-name').show();
			$('#pdf-cond-faucet-kitchen-image').show();
			$('#pdf-cond-faucet-kitchen-name').show();
			$('#pdf-cond-faucet-kitchen-brand').show();
			$('#pdf-4-num-faucet-kitchen').show();
			$('#pdf-4-faucet-kitchen-cost').show();
			$('#pdf-4-faucet-kitchen-total').show();

			$('#pdf-2-faucet-kitchen-image').show();
			$('#pdf-2-num-faucet-kitchen').show();
			$('#pdf-2-current-faucet-kitchen-flowrate').show();
			$('#pdf-2-faucet-kitchen-select-check' + selections['faucet-kitchen'].index).show()
		}

		if(selections['no-faucet-bathroom'] == 'checked')
		{
			$('#pdf-2-faucet-bathroom-image').hide();
			$('#pdf-2-num-faucet-bathroom').hide();
			$('#pdf-2-current-faucet-bathroom-flowrate').hide();

			$('#pdf-4-faucet-bathroom-image').hide();
			$('#pdf-4-faucet-bathroom-name').hide();
			$('#pdf-cond-faucet-bathroom-image').hide();
			$('#pdf-cond-faucet-bathroom-name').hide();
			$('#pdf-cond-faucet-bathroom-brand').hide();
			$('#pdf-4-num-faucet-bathroom').hide();
			$('#pdf-4-faucet-bathroom-cost').hide();
			$('#pdf-4-faucet-bathroom-total').hide();
		}
		else
		{
			$('#pdf-2-faucet-bathroom-image').show();
			$('#pdf-2-num-faucet-bathroom').show();
			$('#pdf-2-current-faucet-bathroom-flowrate').show();

			$('#pdf-4-faucet-bathroom-image').show();
			$('#pdf-4-faucet-bathroom-name').show();
			$('#pdf-cond-faucet-bathroom-image').show();
			$('#pdf-cond-faucet-bathroom-name').show();
			$('#pdf-cond-faucet-bathroom-brand').show();
			$('#pdf-4-num-faucet-bathroom').show();
			$('#pdf-4-faucet-bathroom-cost').show();
			$('#pdf-4-faucet-bathroom-total').show();

			if((selections['faucet-bathroom'].index - 3) < 3)
			{
				$('#pdf-2-faucet-bathroom-select-check' + (selections['faucet-bathroom'].index - 3)).show()
				$('#pdf-2-faucet-bathroom-select-name').hide()
			}
			else
			{
				$('#pdf-2-faucet-bathroom-select-other').show()
				$('#pdf-2-faucet-bathroom-select-name').show()
			}
		}

		$.each(selections, function( name, value ) {
		  //setText('cover-' + name, value)

		  if(value == 'checked' )
		  {
		    $('#pdf-1-' + name).show()
		    $('#pdf-1-amount' + name.substring(5) ).show()
			$('#pdf-2-' + name).show()
			$('#pdf-3-' + name).show()
		  }
		  else if(value == 'unchecked')
		  {
		  	$('#pdf-1-' + name).hide()
		  	$('#pdf-1-amount' + name.substring(5) ).hide()
			$('#pdf-2-' + name).hide()
			$('#pdf-3-' + name).hide()
		  }
		  else
		  {
		  	setText('pdf-0-' + name, value)
		  	setText('pdf-1-' + name, value)
		  	setText('pdf-2-' + name, value)
		  	setText('pdf-3-' + name, value)
		  	setText('pdf-4-' + name, value)
		  	setText('pdf-cond-' + name, value)
		  }
			
		  if(selections['toilet-select'] == 'toilet-10' || selections['toilet-select'] == 'toilet-11'){
			  setText('pdf-cond-toilet-brand', 'EcoLogic')
		  }
		  else
		  {
			  setText('pdf-cond-toilet-brand', 'STEALTH')
		  }
		  
		});

		if(selections['street-address-2'] == "" )
		{
			$("#address-3-down").css({top: 270})
		}
		else
		{
			$("#address-3-down").css({top: 287})
		}

		var u = '';
		(selections['water-units'] == 'Gals') ? u = "RATE / 1000 GALLONS":  u = "RATE / 100 CCF"

		setText('pdf-1-water-units', u)
		setText('pdf-2-water-units', u)
		setText('pdf-3-water-units', u)
		setText('pdf-4-water-units', u)

		setText('pdf-4-deposit','$ '+ numberWithCommas(selections['deposit']))
		setText('pdf-0-delivery-date',selections['delivery-date-flip'])
		
	    setText('pdf-4-other-cost','$ ' + numberWithCommas((selections['other-cost'] * 1).toFixed(2)))

    }
    
    
    
    
    
    //////////////////////////////... CALCULATORS ...//////////////////////////////
    
    
    
    
    
    
    
    
    
    function calcWaterUsage(product){
		
		var results = { currentWaterUsage : 0, futureWaterUsage : 0, gallonsSaved : 0, currentWaterSpend : 0, futureWaterSpend : 0, moneySaved : 0, }
				
		var gallonsPerUnit = 0
		
		if(!$('#no-' + product).prop('checked')){
		
			(getSelect('water-units') == "Gals") ? gallonsPerUnit = getConstant('gallons-per-unit-1000') : gallonsPerUnit = getConstant('gallons-per-unit-ccf')
			
			results['currentWaterUsage'] = getInput('num-people') * /*getInput('num-' + product)*/ getInput('num-units') * getConstant(product + '-time') * getConstant(product + '-days') * getInput('current-' + product + '-flowrate')
			results['futureWaterUsage'] = getInput('num-people') * /*getInput('num-' + product)*/ getInput('num-units') * getConstant(product + '-time') * getConstant(product + '-days') * selections[product].flowrate;
			results['gallonsSaved'] = parseFloat(results['currentWaterUsage']) - parseFloat(results['futureWaterUsage'])
			
			results['currentWaterSpend'] = results['currentWaterUsage'] * getInput('water-cost') / gallonsPerUnit
			results['futureWaterSpend'] = results['futureWaterUsage'] * getInput('water-cost') / gallonsPerUnit
			results['moneySaved'] = parseFloat(results['currentWaterSpend']) - parseFloat(results['futureWaterSpend'])
		
		}
		
		return results
	}
	
	function calcHeatUsage(product)
	{
		var results = { currentHeatUsage : 0, futureHeatUsage : 0, heatSavings : 0 }
		
		if(product != 'toilet' && !$('#no-' + product).prop('checked'))
		{
			results['currentHeatUsage'] = getConstant('hot-water-mult-' + product) * waterUsage[product].currentWaterUsage
			results['futureHeatUsage'] = getConstant('hot-water-mult-' + product) * waterUsage[product].futureWaterUsage
			results['heatSavings'] = parseFloat(results['currentHeatUsage']) - parseFloat(results['futureHeatUsage'])
		}
		
		return results
	}
	
	function calcEnergyUsage(product){
	    
	    var results = { thermAverage : 0, kwhAverage : 0, gasSavings : 0, electricSavings : 0 }
	    
	    if(product != 'toilet' && !$('#no-' + product).prop('checked'))
		{
		    var currentAnnualWater = getInput('current-' + product + '-flowrate') * getConstant(product + '-minutes') * getConstant(product + '-days')
		    var futureAnnualWater = selections[product].flowrate * getConstant(product + '-minutes') * getConstant(product + '-days')
						
		    var currentTherm = currentAnnualWater / getConstant(product + '-therms-existing')
		    var futureTherm = futureAnnualWater / getConstant(product + '-therms-niagara')
		   
		    results['thermAverage'] = (currentTherm + futureTherm) / 2
		    results['gasSavings'] = heatUsage[product].heatSavings / results['thermAverage']
		    
		    var currentKwh = currentAnnualWater / getConstant(product + '-kwh-existing')
		    var futureKwh = futureAnnualWater / getConstant(product + '-kwh-niagara')
		    
		    results['kwhAverage'] = (currentKwh + futureKwh) / 2
		    results['electricSavings'] = heatUsage[product].heatSavings / results['kwhAverage']
		    
		    var currentAnnualGas = getConstant(product + '-therms-existing') * getConstant('cost-per-therm') //might not need!!!!!!!!!!!!!!
		    var futureAnnualGas = getConstant(product + '-therms-niagara') * getConstant('cost-per-therm') //might not need!!!!!!!!!!!!!!
		    var currentAnnualElectric = getConstant(product + '-kwh-existing') * getConstant('cost-per-kwh') //might not need!!!!!!!!!!!!!!
		    var futureAnnualElectric = getConstant(product + '-kwh-niagara') * getConstant('cost-per-kwh') //might not need!!!!!!!!!!!!!!
		    
		}
	    
	    return results
    }
    
    function calcMoneySavings(product){
	    
	    var results = { moneySavingsGas : 0, moneySavingsElectric : 0 }
	    
	    if(product != 'toilet' && !$('#no-' + product).prop('checked'))
		{
	    	results['moneySavingsGas'] = energyUsage[product].gasSavings * getInput('heat-cost')
			results['moneySavingsElectric'] = energyUsage[product].electricSavings * getInput('heat-cost')
	    }
	    return results
    }
    
    
    
   //////////////////////////////... END OF CALCULATORS ...//////////////////////////////
    
    
	function updateNumUnits()
    {
	 //    for(var i = 0; i < productList.length; i++)
	 //    {
		//     p = productList[i]
		// 	setInput('num-' + p,getInput('num-units'))
		// }
		// updateForm()
    }
    
    function updateHeatCost()
	{
		// (getSelect('heat-units') == "Gas") ? setInput('heat-cost',getDefault('heat-cost-gas')) : setInput('heat-cost',getDefault('heat-cost-electric')) 
		// updateForm()
	}
	 function updateWaterCost()
	{
		// (getSelect('water-units') == 'Gals') ? setInput('water-cost',getDefault('water-cost-gals')) : setInput('water-cost',getDefault('water-cost-ccf')) 
		// updateForm()
	}
    
    
    function resetCostDisplays()
    {
	    for(var i = 0; i < productList.length; i++)
	    {
		    p = productList[i]
			setInput(p + '-cost',selections[p].cost)
		}
    }
    
    
    
    function resetToDefaults(){
	     
		xml.find("defaults").each(function() {
		  $.each(this.attributes, function(i, attrib){
		     
		     setInput(attrib.name,attrib.value)
		     setSelect(attrib.name,attrib.value)
		     
		  });
		});
		
		updateSelections()
		resetCostDisplays()
    }
    
    function reloadSelections()
    {
	    url = window.location.href;
		selects = parseParams( url.split('?')[1] || '' );
		
		console.log(selects)
		console.log('update')
		
		$.each( selects, function( key, value ) {
		    setInput(key,value)
		    setSelect(key,value)
		    
		    if(value == 'checked' && key != 'toiletseat[check]' /*&& key != 'warranty[check]'*/ && key != 'supplyline[check]')
		    {
// 			    var temp = key.slice(0,-4)
// 			    console.log(temp)
			    $("input[name=" + key + "]" ).prop('checked', true)
		    }
		    
		});
		
		$("input[name='toiletseat'][value=" + selects['toiletseat-val'] + "]").prop('checked', true)
		$("input[name='supplyline'][value=" + selects['supplyline-val'] + "]").prop('checked', true)
		// $("input[name='warranty'][value=" + selects['warranty-val'] + "]").prop('checked', true)
		
		updateSelections()
		resetCostDisplays()
		
    }
    
    
    
    
    
    
    
    
    
   
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    function initProducts(){
	    
	    xml.find("toilet").each(function(i) {
	    	
	    	var toilet = {
		        	index : i,
					name : $(this).find('name').text(),
					flowrate : $(this).find('flowrate').text(),
					cost : $(this).find('cost').text(),
					altCost : 'none',
					productImage : $(this).find('product-image').text(),
	        	}
		    
		    toilets[i] = toilet
			$('select[name="toilet-select"]').append($('<option>', {value:'toilet-' + toilet.index, text:toilet.name}))
		});
		
		xml.find("showerhead").each(function(i) {
	    	
	    	var showerhead = {
		        	index : i,
					name : $(this).find('name').text(),
					flowrate : $(this).find('gpm').text(),
					cost : $(this).find('cost').text(),
					altCost : 'none',
					productImage : $(this).find('product-image').text(),
	        	}
		    
		    showerheads[i] = showerhead
			$('select[name="shower-select"]').append($('<option>', {value:'showerhead-' + showerhead.index, text:showerhead.name}))
		});
		
		xml.find("faucet").each(function(i) {
	    	
	    	var faucet = {
		        	index : i,
					name : $(this).find('name').text(),
					flowrate : $(this).find('gpm').text(),
					cost : $(this).find('cost').text(),
					loc : $(this)[0].attributes['location'].value,
					altCost : 'none',
					productImage : $(this).find('product-image').text(),
	        	}
		    faucets[i] = faucet
		    
			$('select[name="faucet-' + faucet.loc + '-select"]').append($('<option>', {value:'faucet-' + faucet.index, text:faucet.name}))
		});
		
		xml.find("addon").each(function(i) {
			
			var addon = {
				index : i,
				name : $(this).find('name').text(),
				description : $(this).find('description').text(),
				description2 : $(this).find('description2').text(),
				cost : $(this).find('cost').text(),
				unit : $(this).find('unit').text(),
				type : $(this)[0].attributes['type'].value,
				check : $(this)[0].attributes['check'].value,
			}
			addons[i] = addon
			
			$('#' + addon.type + '-select').append($('<input type="radio" name='+ addon.type +' value=addon-' + addon.index + ' ' + addon.check + '/> <div class="c-form-addon-group"><span class="c-form-addon-name" style="text-transform:uppercase">' + addon.name + '</span></br>' + addon.description + '</br>' + addon.description2 + '</br>Price : ' + addon.cost + ' ' + addon.unit +'</div>'))
		})
    }
    
//     helpers
    
    function parseParams(query)
    {
	    var re = /([^&=]+)=?([^&]*)/g;
	    var decodeRE = /\+/g;  // Regex for replacing addition symbol with a space
		var decode = function (str) {return decodeURIComponent( str.replace(decodeRE, " ") );};
	    
	    var params = {}, e;
	    while ( e = re.exec(query) ) { 
        var k = decode( e[1] ), v = decode( e[2] );
        if (k.substring(k.length - 2) === '[]') {
            k = k.substring(0, k.length - 2);
            (params[k] || (params[k] = [])).push(v);
        }
        else params[k] = v;
    	}
		return params;
    }
    
    function numberWithCommas(x) {
	    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
	
	
		    
//     getters
    
    function getDefault(attribute)
    {
	    return xml.find('defaults')[0].attributes[attribute].value
    }
    
    function getConstant(attribute)
    {
	    return xml.find('constants')[0].attributes[attribute].value
    }
    
    function getSelect(name){
		return $('select[name="' + name +'"]').val()
	}
	
	function getInput(name){
		return $('input[name="' + name +'"]').val()
	}
    
    function getIndex(label){
	    return label.substr(label.indexOf("-") + 1)
    }
    
    
//     setters
	
	function setNumber(id,text)
	{
		$("#" + id).text(numberWithCommas(parseFloat(text).toFixed(2)))
	}
	
	function setText(id,text)
	{
		if(text != "")
		{
			$("#" + id).text(text)
		}
		else
		{
			$("#" + id).text('')
		}
	}
	
	function setInput(name,val)
	{
		$('input[name="' + name + '"]').val(val).change()
	}
	
	function setSelect(name,val)
	{
		$('select[name="' + name + '"]').val(val).change()
	}




});